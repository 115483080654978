@font-face {
  font-family: 'Mona Sans';
  src:
    url('./fonts/Mona-Sans.woff2') format('woff2 supports variations'),
    url('./fonts/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

.vertical {
  writing-mode: vertical-rl;
}

.horizontal {
  writing-mode: horizontal-tb;
}